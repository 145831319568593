import e from"../../_snowpack/pkg/react.js";import n from"../../_snowpack/pkg/styled-components.js";import{removeHyphens as c}from"../utils/index.js";function o({abilities:l=[""],baseExperience:a,height:r,weight:i}){return e.createElement(s,null,e.createElement(t,null,e.createElement("p",null,"Base:"),e.createElement("p",null,a," exp")),e.createElement(t,null,e.createElement("p",null,"Height:"),e.createElement("p",null,r," ft")),e.createElement(t,null,e.createElement("p",null,"Weight:"),e.createElement("p",null,i," lbs")),e.createElement(t,null,e.createElement("p",null,"Abilities:"),e.createElement("div",null,l.map((m,p)=>e.createElement(u,{key:`pokemon-ability-key-${p}`},c(m))))))}const s=n.div`
	display: block;
	margin-inline-start: auto;
	margin-inline-end: auto;

	@media screen and (orientation: landscape) and (max-height: 400px) {
		margin-inline-start: initial;
	}
`,t=n.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
`,u=n.p`
	&::first-letter {
		text-transform: uppercase;
	}
`;export default o;
